import * as React from "react"
import { MdLocationOn, MdPhoneIphone } from 'react-icons/md';
import { BiLogoInstagram } from 'react-icons/bi';

import "../styles/topBar.css"

const TopBar = () => {
  return (
    <div className="topBar">
      <a className="topBarLink" target="_blank" rel="noopener noreferrer" href="https://www.google.com/search?q=iv+restaurant&oq=iv+restaurant"><MdLocationOn className="topBarIcon"/>Immanuelkirchstraße 7, 10405 Berlin</a>
      <a  className="topBarLink" href="tel:+493035521078"><MdPhoneIphone className="topBarIcon"/>030 35521078</a>
      <a  className="topBarLink" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/iv_restaurant/"><BiLogoInstagram className="topBarIcon"/>iv_restaurant</a>
    </div>
  )
}

export default TopBar